// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Slick Carousel
$slick-font-path: "../fonts/";
$slick-loader-path: "../images/";

// Colors
$brand-primary:         #f7dd06;
$dark-yellow:           #e5bf0c;
$black:                 #292929;
$blacker:               #1f1f1f;
$white:                 #ffffff;
$grey:                  #585858;
$off-white:             #f2f2f2;
$blue:                  #09366d;

// Fonts
$rock-bold:            'Rockwell W01 Bold';
$rock-dense:           'RockwellW01-BoldCondens';
$rock-extra:           'Rockwell W01 Extra Bold';
$cambria:              'Cambria W01 Regular';
$cambria-bold:         'Cambria W01 Bold';

//Side-Nav
$sideNavWidth: 260px;
$sideNavDirection: (right);

$button_width: 50px;     // width of button area
$button_height: 60px;     // height of button area
$bar_thickness: 6px;     // thickness of button bars
$bar_roundness: 0;
$button_pad: 0px;      // left/right padding between button area and bars.
$button_bar_space: 8px;     // spacing between button bars
$button_transistion_duration: 0.3s;  // transition duration
$hamburger_normal: $blue;
$hamburger_active: $blue;

// Breakpoint variables
$width_mobile_xs: 375px;
$width_mobile: 480px;
$width_phablet: 560px;
$width_tablet: 768px;
$width_laptop: 992px;
$width_desktop: 1200px;
$width_wide: 1440px;
$width_hd: 1600px;


/*
 * Mixins
 */
// Media query mixins
@mixin mobile-xs { @media (min-width: #{$width_mobile_xs}) { @content; } }
@mixin mobile { @media (min-width: #{$width_mobile}) { @content; } }
@mixin phablet { @media (min-width: #{$width_phablet}) { @content; } }
@mixin tablet { @media (min-width: #{$width_tablet}) { @content; } }
@mixin laptop { @media (min-width: #{$width_laptop}) { @content; } }
@mixin desktop { @media (min-width: #{$width_desktop}) { @content; } }
@mixin wide { @media (min-width: #{$width_wide}) { @content; } }
@mixin hd { @media (min-width: #{$width_hd}) { @content; } }

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin stretch($offset: 0) {
   position: absolute;
   top: #{$offset};
   right: #{$offset};
   left: #{$offset};
   bottom: #{$offset};
   z-index: 99;
}

@mixin smooth-fonts() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin letter-spacing($letter-spacing: 0) {
  letter-spacing: calc(#{$letter-spacing} * 0.001em);
}
