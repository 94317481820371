.hero {
  background: linear-gradient(rgba($blacker,0.5),rgba($blacker,0.5)),
    url(/wp-content/uploads/wp-images/hero-001.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;

  .hero-text {
    padding: 80px 0 90px;
    @include tablet {
      padding: 120px 0;
    }
    @include laptop {
      padding: 0;
      &:before, &:after {
        content: '';
        display: block;
      }

      &:before {
        height: 25vh;
        min-height: 175px;
        max-height: 320px;
      }

      &:after {
        height: 30vh;
        min-height: 205px;
        max-height: 380px;
      }
    }
  }

  h1, p {
    color: $white;
    text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.35);
  }

  h1 {
    font-size: 36px;
    small {
      display: inline-block;
      margin-top: 15px;
      font-size: 26px;
    }
    @include tablet {
      font-size: 52px;
      small {
        font-size: 38px;
        margin-top: 10px
      }
    }
    @include laptop {
      font-size: 60px;
      small {
        font-size: 46px;
      }
    }
  }

  p {
    font-family: $cambria-bold;
    margin: 35px auto 40px;
    line-height: 1.2;
    max-width: 100%;
    font-size: 18px;
    width: 290px;
    @include tablet {
      width: 570px;

    }
  }
}



.services-panel {
  position: relative;
  padding: 0 0 60px;
  @include tablet {
    padding: 50px 0 60px;
  }

  h2 {
    color: $brand-primary;
    text-shadow: 0px 1px 0px rgba($black, 0.35);
    margin: 50px 0 20px;
    @include tablet {
      margin: 80px 0 20px;
    }
    @include laptop {
      margin: 5px 0 20px;
    }
    @include desktop {
      margin: 20px 0;
    }
  }

  .img-block {
    @include tablet {
      background-image: url(/wp-content/uploads/wp-images/asphalt-paving-services-002.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 57%;
      left: 0;
      top: 0;
    }
    @include laptop {
      height: 100%;
      width: 50%;
      bottom: 0;
    }

  }

  .container {
    position: relative;
    z-index: 100;
    > .row {
      justify-content: space-between;
    }
  }

  .services-col {
    flex: 0 0 100%;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include tablet {
      flex-wrap: nowrap;
    }
    @include laptop {
      flex: 0 0 57%;
      max-width: 57%;
    }

  }

  .content-col {
    background-color: $white;
    margin-top: -1px; // Fixing padding offset below
    flex: 0 0 100%;
    padding: 0 15px;
    @include tablet {
      background-color: transparent;
      margin-top: 0;
    }
    @include laptop {
      flex: 0 0 42%;
      max-width: 42%;
    }
  }

  #servicesAccordion {
    background-color: $grey;
    padding-bottom: 1px; // Fixing margin offset for accordion smooth collapse
  }

  .nav.nav-tabs, #servicesAccordion {
    border: none;
    flex: 0 0 100%;
    @include tablet {
      flex: 0 0 240px;
    }
    @include laptop {
      order: 1;
      flex: 0 0 200px;
    }
    @include desktop {
      flex: 0 0 240px;
    }

    .service-tab {
      background-color: $brand-primary;
      display: block;
      color: $black;
      float: none;
      margin: 0;

      &:last-of-type {
        .service-link {
          border-bottom: none;
        }
      }

      p {
        transition: 0.5s ease-in-out;
        font-family: $cambria-bold;
        color: $black;
        margin: 0;
        font-size: 16px;
        @include desktop {
          font-size: 18px;
        }
      }
    }

    .service-link {
      border-radius: 0;
      background-color: $brand-primary;
      border-top: none;
      border-left: none;
      border-right: none;
      text-align: center;
      display: block;
      border-bottom: 1px solid $dark-yellow;
      transform: perspective(1px) translateZ(0);
      transition: 0.5s ease-in-out;
      position: relative;
      padding: 41px 10px;
      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $black;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: 0.5s ease-in-out;
      }

      // &:hover {
      //   @include tablet {
      //     background-color: $brand-primary;
      //     p {
      //       color: $white;
      //     }
      //     &:before {
      //       transform: scaleX(1);
      //     }
      //   }
      // }

      &.active, &[aria-expanded="true"] {
        background-color: $black;
        p {
          color: $white;
        }
        @include tablet {
          background-color: $brand-primary;
          &:before {
            transform: scaleX(1);
          }
        }
      }
    }

  }

  .service-content {

    @include tablet {
      margin-top: 0;
      margin-left: 25px;
    }
    @include laptop {
      margin-right: 15px;
    }
    @include desktop {
      margin-right: 35px;
    }

    &.tab-content {
      background-color: rgba($black,0.7);
      padding: 30px 20px;
    }

    .spacer {
      margin: 30px 20px;
    }

    h3, p, a {
      color: $white;
    }

    a.link {
      font-size: 16px;
    }

    @include laptop {
      order: 0;
    }

    >.tab-pane {
      display: block;
      visibility: hidden;
      overflow: hidden;
      transition: 1s ease-in-out;
      opacity: 0;
      height: 0;
      &.active {
        visibility: visible;
        height: auto;
        opacity: 1;
      }
    }

  }

}
