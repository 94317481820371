.sideNav {
    width: $sideNavWidth;
    #{$sideNavDirection}: -$sideNavWidth;
    position: fixed;
    z-index: 1000;
    height: 100%;
    top: 0;
    background: $black;
    transition: all 0.3s ease;

    .nav {
      li {
        a {
            display: block;
            color: $white;
            font-size: 16px;
            padding: 15px 20px;
            font-family: $cambria-bold;
            @include letter-spacing(10);
        }
      }
    }

    &.sideNav-open {
        #{$sideNavDirection}: 0px;
    }
}

/* classes applied to the body */
.sideNavBody {
    overflow-x: hidden;
    position: relative;
    #{$sideNavDirection}: 0;
    transition: all 0.3s ease;
}

.sideNavBody-push {
    #{$sideNavDirection}: $sideNavWidth;
}
