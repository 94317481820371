header.banner {
  padding: 10px 0;
  .container-fluid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include tablet {
      padding: 0 30px;
    }
    @include laptop {
      padding: 0 15px;
    }
    @include wide {
      padding: 0 35px;
    }

    .brand {
      display: block;
      img {
        width: 170px;
        @include tablet {
          width: 220px;
        }
        @include laptop {
          width: 170px;
        }
        @include desktop {
          width: 228px;
        }
        @include wide {
          width: 248px;
        }
        @include hd {
          width: 268px;
        }
      }
    }

    .nav {
      display: flex;
      justify-content: flex-end;
      li {
        a {
          display: block;
          position: relative;
          transition: all 0.4s ease-in-out;
          color: $black;
          font-size: 14px;
          padding: 5px;
          margin: 0 1px;
          @include desktop {
            font-size: 15.5px;
            padding: 5px 6px;
            margin: 0 2px;
          }
          @include wide {
            font-size: 18px;
            padding: 5px 6px;
            margin: 0 4px;
          }
          @include hd {
            padding: 5px 6px;
            margin: 0 9px;
          }

          &:before, &:after {
            position: absolute;
            opacity: 0;
            width: 0%;
            height: 2px;
            content: '';
            background: $blue;
            transition: all 0.4s ease-in-out;
          }

          &:before {
            left: 0px;
            top: 0px;
          }

          &:after {
            right: 0px;
            bottom: 0px;
          }

          &:hover, &:focus {
            color: $blue;
            &:before, &:after {
              opacity: 1;
              width: 100%;
            }
          }
        }

        &.current_page_item {
          a {
            color: $blue;
            &:before, &:after {
              opacity: 1;
              width: 100%;
            }
          }
        }

        &.menu-phone {
          a {
            &:before, &:after {
              content: none;
            }
            &:hover {
              color: $black;
            }
          }
        }

      }
    }

  }
}
