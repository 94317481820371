.portfolio-panel {
  position: relative;
  text-align: center;
  padding: 90px 0 60px;

  &.footer-portfolio {
    .portfolio-item {
      &.item-3, &.item-4 {
        display: none;
        @include tablet {
          display: block;
        }
      }
    }
  }

  .portfolio-bkg {
    background: linear-gradient(rgba(0,0,0,0.25),rgba(0,0,0,0.25)),
      url(/wp-content/uploads/wp-images/project-gallery-bkg-001.jpg);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 40%;
  }

  h2, h4, p, a {
    color: $white;
  }

  h2 {
    margin-bottom: 20px;
  }

  .container  {
    position: relative;
    z-index: 101;
  }

  .panel-title {
    margin-bottom: 65px;
  }

  .portfolio-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;

    .portfolio-item {
      cursor: pointer;
      position: relative;
      transform: perspective(1px) translateZ(0);
      transition: 0.5s ease-in-out;
      overflow: hidden;
      display: block;
      flex: 0 0 100%;
      @include tablet {
        flex: 0 0 50%;
      }

      .portfolio-img {
        transition: 0.5s ease-in-out;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform: scale(1);
        height: 300px;
      }

      &:before {
        content: '';
        @include stretch();
        background-color: rgba($black, 0.33);
      }

      &:after {
        content: '';
        position: absolute;
        z-index: 100;
        left: 0;
        right: 0;
        bottom: 0;
        height: 5px;
        background: $brand-primary;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: 0.5s ease-in-out;
      }

      h4, p {
        transition: 0.5s ease-in-out;
        color: rgba($white, 0.8);
        @include laptop {
          color: rgba($white, 0.33);
        }
      }

      &:hover, &:focus {
        .portfolio-img {
          transform: scale(1.1);
        }

        h4, p{
          color: $white;
        }

        &:after {
          transform: scaleX(1);
        }
      }
    }

    .portfolio-title {
      padding: 0 15px;
      position: absolute;
      width: 100%;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 101;

      p {
        margin: 0;
      }
    }
  }

  .portfolio-footer {
    background-color: $white;
    border-top: 5px solid $brand-primary;
    height: 400px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 60%;
  }
}



footer.content-info {
  background-color: $off-white;
  border-top: 5px solid $brand-primary;
  text-align: center;
  .hide-footer {
    display: none!important;
  }

  .footer-content {
    padding: 40px 0 30px;
  }

  .brand {
    display: block;
    img {
      width: 240px;
      @include laptop {
        width: auto;
      }
    }
  }

  .nav-primary {
    margin: 0 auto;
    width: 600px;
    max-width: 100%;
    @include laptop {
      width: auto;
    }
  }

  .nav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 35px 0 15px;
    @include laptop {
      justify-content: space-between;
    }
    li {
      a {
        display: block;
        font-family: $cambria;
        transition: 0.4s ease-in-out;
        padding: 5px 16px;
        font-size: 18px;
        color: $black;
        @include laptop {
          padding: 5px;
        }
        &:hover, &:focus {
          color: $blue;
        }
      }
    }
  }

  .footer-cta {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    flex-wrap: wrap;
    @include tablet {
      flex-wrap: nowrap;
    }
    .cta-text {
      margin-bottom: 20px;
      @include tablet {
        margin-right: 25px;
        margin-bottom: 0;
      }
      @include desktop {
        margin-right: 35px;
      }
    }
    h4 {
      line-height: 1;
      color: $blue;
      margin: 0;
      @include desktop {
        font-size: 36px;
      }
    }
  }

  .footer-contact {
    font-family: $cambria;
    margin-bottom: 20px;
    color: $black;
    font-size: 18px;

    @include tablet {
      display: flex;
      justify-content: center;
      align-items: center;
    }


    .address {
      margin-bottom: 15px;
      @include tablet {
        margin-bottom: 0;
        margin-right: 10px;
        justify-content: center;
        display: flex;
      }
      > div {
        @include letter-spacing(5);
        @include tablet {
          border-right: 1px solid $black;
          line-height: 1;
          padding: 0 6px;
        }
        &:last-of-type {
          border: none;
        }
      }
    }

    .social {
      a {
        margin: 0 3px;
        color: $black;
        font-size: 40px;
        display: inline-block;
        transition: 0.3s ease-in-out;

        @include tablet {
            font-size: 30px;
        }

        &:hover, &:focus {
          color: $blue;
        }
      }
    }

  }

  .payment {
    .fa {
      font-size: 40px;
      margin: 0 3px;
    }
  }
}

.copyright {
  background-color: $blacker;
  text-align: center;
  padding: 6px 0;

  * {
    color: $white;
    @include letter-spacing(75);
    font-family: $cambria-bold;
    font-size: 12px;
  }

  .copy-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include laptop {
      flex-wrap: nowrap;
    }
  }
}
