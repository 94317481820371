.hero {

  &.interior-hero {
    .hero-text {
      &:before, &:after {
        content: none;
      }
    }
  }

  &.page-hero {
    .hero-text {
      padding: 100px 0;
    }
  }

  &.landing-page-hero {
    .hero-text {
      padding: 100px 0;
      @include laptop {
        padding: 200px 0;
      }
      h1 {
        margin-bottom: 35px;
      }
    }
  }


}


.page-content {
  padding: 60px 0;
  @include laptop {
    padding: 70px 0;
  }
}

@include laptop {
    .contact {
        .page-content {
            min-height: 600px;
        }
    }
}

.page-wrap {
  border-top: 5px solid $brand-primary;
  border-bottom: 5px solid $brand-primary;
  position: relative;

  > .container {
    position: relative;
    z-index: 101;
  }

  h1 {
    font-size: 46px;
    margin: 0 0 25px;
  }

  h4 {
    text-transform: none;
    margin: 0 0 10px;
  }

  .img-block {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 40%;
    bottom: 0;
    left: 0;
    top: 0;
  }

}

.content-box {
  background-color: rgba($black,0.7);
  padding: 30px 20px;

  h1, h2, h3, h4, h5, p, li {
    color: $white;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  &.contact-box {
    position: absolute;
    z-index: 101;
    top: 30px;
    right: 20px;
    left: 20px;

    max-width: 100%;
    @include phablet {
      left: auto;
      width: 340px;
    }
    @include laptop {
      top: 50px;
      right: 40px;
    }
  }
}


.service-map-container {
  position: relative;
  height: 500px;
  
  @include laptop {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 55%;
    @include desktop {
      width: 49%;
    }
  }

  #serviceMap {
    @include laptop {
      height: 100%!important;
    }
  }
}


#projectCarousel {
  .portfolio-images {
    img {
      width: 100%;
    }
  }

  .slick-arrow {
    height: 50px;
    top: calc(100% - 18px);
    transform: translateY(0);
    @include tablet {
      top: 50%;
      transform: translateY(-50%);
    }
    &:before {
      font-family: FontAwesome;
      font-size: 40px;
      color: $black;
      @include tablet {
        font-size: 50px;
        color: $white;
      }
    }
    &.slick-prev {
      left: 0;
      @include tablet {
        left: -60px;
      }
      &:before {
        content: '\f104';
      }
    }
    &.slick-next {
      right: 0;
      @include tablet {
        right: -60px;
      }
      &:before {
        content: '\f105';
      }
    }
  }
}

#projectModal {

  &.in {
    display: flex!important;
    align-items: center;
    justify-content: center;
  }

  &.loading {
    visibility: hidden;
    overflow: hidden;
    height: 0;
  }

  .modal-dialog {
    margin: 0;
    max-width: 100%;
    flex: 0 0 100%;
    max-height: 100%;
    @include tablet {
      max-width: 650px;
    }
  }

  .modal-content {
    border-radius: 0;
    padding: 20px 20px 40px;
    @include tablet {
      padding: 20px;
    }
  }

  .project-single {

    h4 {
      margin: 0 0 25px;
      max-width: calc(100% - 25px);
    }

    .lSSlideOuter {
      margin-bottom: 20px;
    }
  }

  .close {
    position: absolute;
    font-size: 40px;
    z-index: 1000;
    right: 17px;
    top: 13px;
  }
}

.lSAction>a {
  background-image: url(../images/controls.png);
}

.lSSlideOuter .lSPager.lSGallery {
  li {
    // height: 75px
  }
}

.error404-inner {
    padding: 80px 0;
}
