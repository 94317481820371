* {
  outline: 0 !important;
}

html, body {
  @include smooth-fonts();
  background-color: $black;
  font-family: $cambria;
  font-size: 16px;
  color: $black;
}

img {
  max-width: 100%;
  height: auto;
}

.no-wrap {
  white-space: nowrap;
}

.rich-card-image {
  display: none!important;
}

.content-container {
  background-color: $white;
  min-height: 100vh;
  margin: 0 auto;
  @include wide {
    max-width: 1400px;
  }
  @include hd {
    max-width: 1600px;
  }
}

.container {
  @include laptop {
    max-width: 980px;
  }
  @include desktop {
    max-width: 1200px;
  }
}

h1 {
  font-family: $rock-extra;
  line-height: 1;
  font-size: 60px;
  small {
    font-size: 46px;
  }
}

h2 {
  font-family: $rock-dense;
  font-size: 52px;
  @include tablet {
    font-size: 60px;
  }
  @include desktop {
    font-size: 72px;
  }
}

h3 {
  font-family: $rock-dense;
  font-size: 48px;
  margin: 0 0 25px;
  @include desktop {
    font-size: 50px;
  }
}

h4 {
  font-family: $rock-bold;
  font-size: 30px;
  @include laptop {
    font-size: 32px;
  }
}

h5 {
  font-family: $cambria-bold;
  font-size: 18px;
}

h1, h2, h3, h4, h5 {
  text-transform: uppercase;
}

p, li {
  font-family: $cambria;
  font-size: 16px;
  line-height: 1.4;
  @include letter-spacing(-25);
  color: $grey;
  &.subtitle {
    font-size: 18px;
    text-transform: uppercase;
  }
}

p, ul {
  margin-bottom: 20px;
}

a {
  color: $blue;
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: none;
  }
}

ul.flex-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  li {
    flex: 0 0 100%;
    @include phablet {
      flex: 0 0 50%;
    }
  }
}

.nav {
  li {
    a {
      @include letter-spacing(5);
    }
  }
}

.portfolio-videos {
    display: flex;
    flex: 1 0 100%;
    max-width: 100%;
    flex-wrap: wrap;

    .portfolio-video {
        display: flex;
        flex-wrap: nowrap;
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
        padding-right: 15px;
        padding-left: 15px;

        @include tablet {
            flex: 0 1 50%;
            max-width: 50%;
        }
    }
}

.video-wrapper {
    video {
      width: 100%!important;
      height: auto!important;
    }
}

.entry-content-asset {
    position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
    width: 100%;

    iframe {
	   position: absolute;
	   top: 0;
	   left: 0;
	   width: 100%;
	   height: 100%;
    }
}
