// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}


.gform_wrapper {
  margin-top: 20px;
  ul {
    list-style-type: none;
    padding: 0;

    .gfield {

      label {
        margin: 0 0 5px;
      }
      input, textarea {
        -webkit-appearance: none;
        -moz-appearance: none;
        box-shadow: none;
        border: 2px solid $black;
        border-radius: 0;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
      }

      textarea {
        height: 160px;
      }
    }

    #field_1_5 label {
      display: none;
    }
  }

  input[type=submit] {
    @extend .btn;
    @extend .btn-blue;
  }
}
