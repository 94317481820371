.btn {
  font-family: $rock-bold;
  text-transform: uppercase;
  transition: 0.5s ease-in-out;
  border-radius: 0;
  font-size: 14px;
  padding: 18px 10px;
  max-width: 100%;
  border: none;
  @include mobile-xs {
    font-size: 15px;
    padding: 18px 20px;
  }
  @include tablet {
    padding: 18px 40px;
    font-size: 18px;
  }

  &.btn-primary {
    color: $black;
    &:hover, &:focus {
      color: $black;
      background-color: $dark-yellow;
    }
  }

  &.btn-blue {
    color: $white;
    background-color: $blue;
    &:hover, &:focus {
      background-color: darken($blue,10);
    }
  }

  &.cta-btn {
    font-size: 16px;
    @include mobile-xs {
      font-size: 18px;
    }
    @include tablet {
      font-size: 20px;
    }
    @include laptop {
      font-size: 22px;
    }
  }
}


a.link {
  border-bottom: 1px dashed $blue;
  transition: 0.4s ease-in-out;
  display: inline-block;
  padding-bottom: 3px;
  font-size: 18px;
  color: $blue;
  &:hover, &:focus {
    border-bottom: 1px solid $blue;
  }

  &.white {
    border-bottom: 1px dashed $white;
    color: $white;
    &:hover, &:focus {
      border-bottom: 1px solid $white;
    }
  }
}
